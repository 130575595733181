<template>
  <div class="menu" :style="menuStyle">
    <div class="menu-hd">
      <div class="identity" :style="logoStyle">
        <img class="logo" src="./logo.svg" />
        <h1 class="system_name">{{ APP_NAME }}</h1>
      </div>
      <div class="controller-icon" :class="menuControllerClass" :style="menuControllerStyle" @click="menuController"></div>
    </div>
    <el-menu class="menu-main el-menu" @select="select" v-bind="menu_options" :default-active="curRoute" :collapse="!menu_grow">
      <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
      <el-submenu :index="String(menu.id)" v-for="menu in menu_list" :key="menu.id" v-if="menu.children">
        <template slot="title">
          <i class="iconfont" :class="menu.icon"></i>
          <span slot="title">{{ menu.menuName }}</span>
        </template>
        <el-menu-item :index="child_menu.component" style="padding-left: 70px" v-for="child_menu in menu.children" :key="child_menu.id">{{ child_menu.menuName }}</el-menu-item>
      </el-submenu>
      <el-menu-item :index="menu.component" v-else>
        <i class="iconfont" :class="menu.icon"></i>
        <span slot="title">{{ menu.menuName }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import localMenu from '@/assets/mock/menu.json';
// import { mapGetters } from 'vuex';
import { APP_NAME } from '/app.config.js';
// import { APP_NAME, offline } from '/app.config.js';
export default {
  data() {
    return {
      APP_NAME,
      menu_grow: true,
      menu_options: {
        'background-color': '#121212',
        'text-color': '#C2C2C2',
        'unique-opened': true,
        router: false
      },
      blanks: ['/map', '/factoryBoard']
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.fullPath === '/') {
        let path;
        const authMenus = JSON.parse(localStorage.getItem('user')).permissions.flatten_menus;
        if (authMenus.includes('/deviceInfo')) {
          path = '/deviceInfo';
        } else {
          path = authMenus.filter(menu => !this.blanks.includes(menu))[0];
        }
        this.$router.push(path);
      }
    });
  },
  methods: {
    select(indexPath) {
      const { fullPath } = this.$route;

      if (this.blanks.includes(indexPath)) {
        const url = location.origin + process.env.BASE_URL + indexPath.split('/')[1];
        window.open(url, '_blank');
        this.$router.go(0);
      } else if (indexPath !== fullPath) {
        this.$router.push(indexPath);
      }
    },
    menuController() {
      this.menu_grow = !this.menu_grow;
    }
  },
  watch: {
    menu_grow(v) {
      const width = v ? '212px' : '64px';
      this.$emit('update:menuWidth', width);
    }
  },
  computed: {
    // ...mapGetters(['getUserInfo']),
    curRoute() {
      return this.$route.meta.menuActive || this.$route.path;
    },
    menu_list() {
      return localMenu;
      // if (offline) {
      //   return localMenu;
      // } else if (this.getUserInfo.permissions) {
      //   return this.getUserInfo.permissions.menu;
      // } else {
      //   return [];
      // }
    },
    menuStyle() {
      return {
        width: this.menu_grow ? '212px' : '64px'
      };
    },
    logoStyle() {
      return {
        left: !this.menu_grow ? '-260px' : '20px',
        opacity: this.menu_grow ? '1' : '0'
      };
    },
    menuControllerClass() {
      return this.menu_grow ? 'el-icon-s-fold' : 'el-icon-s-unfold';
    },
    menuControllerStyle() {
      return {
        left: this.menu_grow ? '180px' : '20px'
      };
    },
    jointlyStyle() {
      return {
        left: !this.menu_grow ? '-260px' : '',
        opacity: this.menu_grow ? '1' : '0'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.iconfont {
  margin-right: 16px;
}
.logo {
  width: auto;
  height: 28px;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.menu {
  width: 212px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: width 0.5s;
  background: #121212;
  .menu-hd {
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 16px;
    height: 61px;
    .identity {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: left 0.8s;
      transition: opacity 0.1s;
      .system_name {
        width: 112px;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        text-align: center;
        color: #f39800;
        margin-left: 6px;
      }
    }
    .controller-icon {
      font-size: 16px;
      color: #585f70;
      cursor: pointer;
      position: absolute;
      transition: all 0.5s;
    }
    .controller-icon:hover {
      color: var(--primary-color);
    }
  }

  .jointly {
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-menu {
    width: 100%;
    flex: 1;
    height: 0;
    border-right: none !important;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
  }

  .el-menu:not(.el-menu--collapse) {
    width: 212px;
    min-height: 400px;
  }
}
</style>
