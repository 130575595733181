<template>
  <div class="app">
    <aside class="aside">
      <app-menu></app-menu>
    </aside>
    <section class="container">
      <header class="header">
        <app-header></app-header>
      </header>
      <main class="">
        <router-view />
      </main>
    </section>
  </div>
</template>

<script>
import appHeader from '@/components/header/header';
import appMenu from '@/components/menu/menu';
export default {
  components: {
    appHeader,
    appMenu
  }
};
</script>

<style lang="scss">
main {
  overflow-y: auto;
}
.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  > .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f3f3f3;
    padding: 0 20px 20px 20px;
    overflow: hidden;
    border-radius: 5px;
    .main {
      flex: 1;
      background: #fff;
      padding: 20px;
      overflow: auto;
      border-radius: 6px;
      position: relative;
    }
  }
}
</style>
