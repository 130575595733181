<template>
  <div class="header">
    <div class="Breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="(breadcrumb, index) in breadcrumb_list" :key="index"
          ><a :href="breadcrumb.url ? breadcrumb.url : 'javascript:;'">{{ breadcrumb.title ? breadcrumb.title : breadcrumb }}</a></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="nav-top">
      <ul class="nav-list">
        <!-- <li class="nav-item iconfont icon-dchead_icon_nav" @click.stop="getNavPath"></li> -->
        <!-- <li class="nav-item iconfont icon-dchead_icon_help"></li> -->
        <!-- <li class="nav-item iconfont icon-dchead_icon_full"></li> -->
        <li class="nav-item user-group">
          <el-dropdown trigger="click" @command="commandHandler">
            <el-avatar :size="30" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="editPwd">修改密码 </el-dropdown-item>
              <el-dropdown-item command="logout">退出登录 </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
    <baseDialog
      title="修改密码"
      :dialogVisible="baseDialogVisible"
      visibleName="baseDialogVisible"
      @cancel="cancel($event)"
      @submit="submitSure($event)"
      :isSend="isSend"
      :btnTxt="btnTxt"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="原密码" :label-width="formLabelWidth" prop="oldPassword">
          <el-input type="password" v-model="form.oldPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth" prop="newPassword">
          <el-input type="password" v-model="form.newPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :label-width="formLabelWidth" prop="confirmPassword">
          <el-input type="password" v-model="form.confirmPassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
    </baseDialog>
  </div>
</template>

<script>
import { toAuthorization } from '@/axios/utils';
import baseDialog from '@/components/dialog/base-dialog';
export default {
  components: {
    baseDialog
  },
  data() {
    return {
      baseDialogVisible: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      isSend: false,
      btnTxt: '确定',
      rules: {
        oldPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        confirmPassword: [{ required: true, message: '请确认密码', trigger: 'blur' }]
      },
      formLabelWidth: ' 80px'
    };
  },
  methods: {
    commandHandler(type) {
      if (type === 'editPwd') {
        this.baseDialogVisible = true;
      } else {
        this.logout();
      }
    },
    logout() {
      this.$confirm('确认退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        console.log(1);
        const { fail } = await this.$apis.home.logout();
        if (!fail) {
          toAuthorization();
        }
      });
    },
    cancel() {
      this.baseDialogVisible = false;
    },
    submitSure() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.changePwd();
        }
      });
    },
    async changePwd() {
      this.isSend = true;
      this.btnTxt = '处理中...';
      const { resp_msg, resp_code } = await this.$apis.home.changePassword(this.form);
      if (resp_code === 0) {
        this.cancel();
        this.$message({
          type: 'success',
          message: resp_msg
        });
        this.form = {
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        };
      } else {
        this.$message({
          type: 'error',
          message: resp_msg
        });
      }
      this.isSend = false;
      this.btnTxt = '确定';
    }
    // async getNavPath() {
    //   const res = await this.$apis.nav_path.get();
    //   location.href = res;
    // }
  },
  computed: {
    breadcrumb_list() {
      return this.$route.meta.breadcrumb;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
.header {
  height: 43px;
  margin: 9px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/deep/ .el-breadcrumb {
  .el-breadcrumb__item {
    &:last-child {
      .el-breadcrumb__inner {
        color: $--color-primary;
      }
    }
  }
}

.nav-top {
  .nav-list {
    display: flex;
    align-items: center;
    .nav-item,
    .user-group {
      cursor: pointer;
    }
    .nav-item {
      width: 20px;
      line-height: 20px;
      text-align: center;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .user-group {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
}
</style>
